<template>
    <div class="row">
        <div class="col-12 my-4">
            <el-input placeholder="Buscar Vídeos de Yoga" v-model="search">
                <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
        </div>
        <div class="col-12">
            <div class="row">
                <div v-for="video in filtered(videos)" class="col-sm-6 col-lg-4">
                    <div class="card shadow mb-5">
                        <div class="card-body embed-responsive embed-responsive-16by9" >
                           <span v-html="video.iframe"></span>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title">{{video.title}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                search: '',
                videos: [],
            }
        },
        methods: {
            filtered(videos) {
                var self = this;
                return videos.filter(video => {
                    return video.title.toLowerCase().includes(self.search.toLowerCase())
                })
            }
        },
        created(){
            axios.get("/api/videos")
                .then( res => {
                    this.videos=res.data.videos;
                })
                .catch(e => {
                    console.log(e.response);
                })
        }

    }
</script>
