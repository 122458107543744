
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./argon');

window.Vue = require('vue');

import locale from 'element-ui/lib/locale/lang/en'
import Element from 'element-ui'
import SearchVideos from './components/SearchVideos.vue'

Vue.use(Element, { size: 'large',locale });

const app = new Vue({
    el: '#app',
    components: {
  		'search-videos': SearchVideos,
  	},
});
